<template>
  <div class="send-money-promo-code-modal">
    <AppModal
      v-model="model"
      :title="$t('SendMoneyAmount.ReferAFriend.PromoCodeModal.Title').value"
    >
      <template #header>
        <AppModalHeader>
          <h1>{{ $t('SendMoneyAmount.ReferAFriend.PromoCodeModal.Title').value }}</h1>
          <template #right>
            <XeBackButton icon="x" @click="$router.toParentRoute()" />
          </template>
        </AppModalHeader>
      </template>

      <template #default>
        <form id="promo-code-form" @submit.prevent>
          <AppInputText
            v-model="promoCode"
            :disabled="isSaving"
            type="text"
            :placeholder="$t('SendMoneyAmount.ReferAFriend.PromoCodeModal.Placeholder').value"
            :class="{ 'input-promo-error': promoError, 'promo-code-input': true }"
            @input="resetValidation"
          />
          <p class="promo-error-text" v-if="promoError">{{ promoError }}</p>
        </form>
      </template>

      <template #footer>
        <AppModalFooter>
          <AppButton
            type="submit"
            form="promo-code-form"
            :loading="isSaving"
            :disabled="promoCode.length < 1 || promoError"
            @click="addPromoCode"
          >
            {{ $t('SendMoneyVoltPayToStartPage.ButtonContinue').value }}
          </AppButton>
        </AppModalFooter>
      </template>
    </AppModal>
    <!-- Information modal -->
    <InformationAppModal
      :icon="ICON_TYPE.CHECKMARK_CIRCLE"
      :shouldDisplayCloseButton="false"
      :value="promoCodeUpdated"
    >
      <h1 class="title">
        {{ $t('SendMoneyAmount.ReferAFriend.PromoAddedModal.Title').value }}
      </h1>
      <p>{{ promotionAddedText }}</p>
      <template #footer>
        <AppButton theme="primary" @click="closeModal">
          {{ $t('SendMoneyAmount.ReferAFriend.PromoAddedModal.Button').value }}
        </AppButton>
      </template>
    </InformationAppModal>
  </div>
</template>

<script>
import { useRouter } from '@galileo/composables/useRouter'
import { ref, computed } from '@vue/composition-api'
import XeBackButton from '@galileo/components/XeBackButton/XeBackButton'
import InformationAppModal, {
  ICON_TYPE,
} from '@galileo/components/InformationAppModal/InformationAppModal'
import {
  AppButton,
  AppInputText,
  AppModal,
  AppModalHeader,
  AppModalFooter,
  useVModel,
} from '@oen.web.vue2/ui'
import {
  useI18nStore,
  usePromotionStore,
  useSendMoneyStore,
  useAnalyticsStore,
} from '@galileo/stores'

import { SEGMENT_EVENTS } from '@galileo/constants/segmentAnalytics'
import { PROMOTIONS_TYPE } from '@galileo/constants/promotions.const'

export default {
  name: 'SendMoneyPromoCodeModal',
  components: {
    XeBackButton,
    InformationAppModal,
    ICON_TYPE,
    AppButton,
    AppInputText,
    AppModal,
    AppModalHeader,
    AppModalFooter,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const router = useRouter()
    const { model } = useVModel(props, emit)

    const { $t } = useI18nStore()
    const promotionStore = usePromotionStore()
    const sendMoneyStore = useSendMoneyStore()
    const analyticsStore = useAnalyticsStore()

    const { promotionsNames } = promotionStore

    const closeModal = () => {
      promoCodeUpdated.value = false
      router.toParentRoute()

      promoCode.value = ''
    }

    const promoCode = ref('')
    const isSaving = ref(false)
    const promoCodeUpdated = ref(false)
    const promoError = ref('')

    const resetValidation = () => {
      promoError.value = ''
    }

    const getActivePromotionStatus = () => {
      if (promotionStore.activePromotion?.promotionCode.valid) {
        return 'valid'
      } else {
        return 'invalid'
      }
    }

    const addPromoCode = async () => {
      isSaving.value = true

      //promos should always be sent upper case
      sendMoneyStore.form.promotionCodes = [promoCode.value.toUpperCase()]

      await sendMoneyStore.calculate({ summary: false, debounceTime: 0 })

      const activePromotion = promotionStore.activePromotion

      const activePromoStatus = getActivePromotionStatus()

      const isPromoCodeValid = activePromoStatus === 'valid'

      analyticsStore.track({
        event: SEGMENT_EVENTS.PROMO_CODE_ADDED,
        traits: {
          promoCode: promoCode.value,
          promoApplied: activePromoStatus,
        },
      })

      if (isPromoCodeValid) {
        analyticsStore.track({
          event: SEGMENT_EVENTS.PROMOTION_SELECTED,
          traits: {
            promoAdded: true,
            promosAvailable: promotionsNames,
            promoCode: activePromotion.promotionCode.code,
            promoName: activePromotion.name,
            loggedIn: true,
          },
        })

        promoCodeUpdated.value = true
      } else {
        const invalidPromotion = promotionStore.invalidPromotion

        if (invalidPromotion) {
          const promoErrorTranslated = $t(
            `Promo.ErrorCode.${invalidPromotion.promotionCode.errorCode}`,
            {
              fallbackKey: 'SendMoneyAmount.ReferAFriend.PromoCodeModal.PromoCodeLengthError',
            }
          ).value
          promoCodeUpdated.value = false
          promoError.value = promoErrorTranslated
        } else {
          promoCode.value = ''
          promoError.value = $t('error.tryAgain').value
        }
        sendMoneyStore.form.promotionCodes = []
      }

      isSaving.value = false
    }

    const promotionAddedText = computed(() => {
      if (promotionStore.activePromotion) {
        const activePromotionData = promotionStore.getActivePromoData()
        const promoText = ref('')

        if (activePromotionData.name == PROMOTIONS_TYPE.REFER_FRIEND) {
          promoText.value = $t('SendMoneyAmount.ReferAFriend.PromoCode.PromoAddedText', {
            currency: activePromotionData.data.symbol,
            qualifyingAmount: activePromotionData.data.qualifyingAmount,
            giftCardAmount: activePromotionData.data.giftCardAmount,
          }).value
        } else {
          promoText.value = $t('SendMoneyAmount.ReferAFriend.OtherPromoCode.PromoAddedText', {
            currency: activePromotionData.data.symbol,
            qualifyingAmount: activePromotionData.data.qualifyingAmount,
            giftCardAmount: activePromotionData.data.giftCardAmount,
          }).value
        }

        return promoText.value
      }
    })

    return {
      model,
      closeModal,
      $t,
      promoCode,
      resetValidation,
      addPromoCode,
      ICON_TYPE,
      isSaving,
      promoCodeUpdated,
      promoError,
      promotionAddedText,
    }
  },
}
</script>

<style scoped>
.title {
  @apply text-xl font-bold text-center;
}

.send-money-promo-code-modal {
  ::v-deep {
    .card-content {
      .card-content-block {
        @apply mb-8;
      }

      #promo-code-form {
        .promo-code-input {
          @apply mb-0;
        }
        .promo-error-text {
          @apply text-sm;
          color: #cd3d64;
        }
        .input-promo-error input {
          border: 2px solid #ed5f74;
        }
        input {
          @apply uppercase;
          &::placeholder {
            @apply normal-case;
          }
        }
      }
    }

    .card-footer {
      button {
        @apply w-full;
      }
    }

    .info-app-modal {
      .card {
        @apply p-12;
      }
      .modal-body > p {
        @apply mt-5;
      }
      .card-header {
        padding: 0 0 2rem 0;
      }
      .card-header-title {
        @apply h-auto;
      }
      .card-content,
      .card-footer {
        @apply p-0;
      }
      .card-footer .button {
        @apply w-full mt-0 !important;
      }
    }
  }
}
</style>
